<template>
  <el-row style="height: 100vh;overflow:hidden;">
    <!-- <topHeader :class="{header:isIndexGetter}"></topHeader> -->
    <!-- <el-header
      style="width: auto;overflow: hidden;background-color:#4394E5;height: 70px;"
    >
      <common-aside></common-aside>
    </el-header> -->
    <div class="photo" :class="{ enphoto: isEnPhoto }">
      <div class="all w">
        <div class="logo">
          <img src="../../../assets/configImages/dllogo.png" alt="" width="50%">
          <img src="../../../assets/configImages/hydl.png" alt="" width="50%">
        </div>
        <div class="box componentBackgroundColor">
          <h1 class="immediately">
            <img src="../../../assets/configImages/ljdl.png" alt="" width="100%">
          </h1>
          <!-- <h1 class="immediately">立即登录</h1> -->
          <div class="content">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              :label-position="labelPosition"
            >
              <el-form-item prop="tel" label="" class="One">
                <div class="lable">
                    账号 
                    <span>Account number</span>
                </div>
                <el-input
                  type="text"
                  class="eleInputLogin"
                  prop="tel"
                  v-model="ruleForm.tel"
                  placeholder="请输入您的账号"
                ></el-input>
              </el-form-item>
              <el-form-item prop="passWord" label="" class="Two">
                <div class="lable lable1">
                    登录密码
                    <span>Login password</span>
                </div>
                <el-input
                  :type="passwordText"
                  class="eleInputLogin"
                  prop="passWord"
                  v-model="ruleForm.passWord"
                  @keyup.enter.native="submitForm('ruleForm')"
                  placeholder="请输入您的登录密码"
                >
                  <img
                    slot="suffix"
                    :src="closeEye"
                    v-if="isPasstype"
                    @click="showPwd"
                  />
                  <img slot="suffix" :src="openEye" v-else @click="hidePwd" />
                </el-input>
              </el-form-item>
              <el-form-item
                prop="tupian"
                class="Three yanZheng"
                label=""
              >
              <div class="lable lable2">
                    图形验证码
                    <span>Graphic Verification Code</span>
                </div>
                <el-input
                  type="text"
                  class="eleInputLogin"
                  prop="tupian"
                  v-model="ruleForm.tupian"
                  @keyup.enter.native="submitForm('ruleForm')"
                  placeholder="请输入图形验证码"
                ></el-input>
                <img
                  :src="captchaData"
                  class="tupian"
                  @click="VerificationCode()"
                />
              </el-form-item>
              <!-- <div class="remember">
                <input type="checkbox" v-model="checked" class="changes enChanges">
                <span class="word enWord">请记住我</span>
              </div> -->
              <input
                type="button"
                class="com btn loginSubmitBtn"
                @click="submitForm('ruleForm')"
                :disabled="btnEnable"
                value="登  录"
              />
            </el-form>
            <router-link tag="li" to="/register/info">
              <a href class="reg">立即注册</a>
            </router-link>
            <router-link tag="li" to="/forget">
              <a href class="forget">忘记密码</a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- <Footer /> -->
    <!-- <div class="footerBox"></div> -->
  </el-row>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import md5 from "js-md5";
import noNavHeader from "../../base/header/noNavHeader";
import topHeader from "@/components/base/header/topHeader";
import Footer from "@/components/base/footer/footer";
import CommonAside from "./loginTop";
export default {
  data() {
    return {
      // isPcClient: window.g.IS_PC_CLIENT,
      code1: "",
      ieoId: "",
      slideText: "向右滑",
      clientHeight: "600px",
      captchaData: "",
      checked: false,
      labelPosition: "right",
      btnEnable: false,
      // clientIp: returnCitySN['cip'] ? returnCitySN['cip'] : '',
      ruleForm: {
        tel: "",
        passWord: "",
        tupian: ""
      },
      isPasstype: true,
      passwordText: "password",
      rules: {
        tel: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur"
          }
          // {
          //   min: 11,
          //   max: 11,
          //   message: "请输入11位有效手机号码",
          //   trigger: 'blur'
          // }
        ],
        passWord: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur"
          }
        ],
        tupian: [
          {
            required: true,
            message: "请输入图形验证码",
            trigger: "blur"
          }
        ]
      },
      isEnPhoto: false,
      closeEye: require("../../../assets/images/closeeye.png"),
      openEye: require("../../../assets/images/openeye.png")
    };
  },
  components: {
    noNavHeader,
    Footer,
    topHeader,
    CommonAside
  },
  computed: {
    ...mapGetters(["isIndexGetter"])
  },
  created() {
    this.$store.commit("setSessionInfo", "");
    this.$store.commit("setUserType", "");
    this.$store.commit("setSelectFirmApplyCommodity", "");
    localStorage.setItem("atPresentFirmId", 0);
    sessionStorage.clear();
  },
  mounted() {
    localStorage.setItem("bidFlagss", 0);
    clearInterval(this.timeInterval1);
    // 当刷新页面时候获取一下localStorage里存的值
    this.getlocalStorage();
    // 当页面刷新时获取图片验证码
    this.VerificationCode();
    // 当页面刷新的时候，如果input里面有值，则checked为选中状态
    if (this.ruleForm.tel != "") {
      this.checked = true;
    }
    this.setLanguageCss();
    this.getUrl();
    this.$store.commit("setHeaderText", "");
  },
  methods: {
    showPwd() {
      this.isPasstype = false;
      this.passwordText = "text";
    },
    hidePwd() {
      this.isPasstype = true;
      this.passwordText = "password";
    },
    param_getMarketParameter() {
      http.postFront(protocolFwd.param_getMarketParameter).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.$store.commit("setMarginRate", value);
        } else {
          console.log(message);
        }
      });
    },
    searchFirmId(id) {
      protocolFwd.param_firmIdSearch.param.userId = id;
      http.postFront(protocolFwd.param_firmIdSearch).then(response => {
        var { message, value, code } = response.data;
        if (Number(code) === 0) {
          localStorage.setItem("FirmIdList_w", JSON.stringify(value));

          // if (localStorage.getItem("FirmIdList_w")) {
          //   var arr = JSON.parse(localStorage.getItem("FirmIdList_w"));
          //   if (arr.length > 0) {
          //     sessionStorage.setItem("marketId_w", arr[0].marketId);
          //   } else {
          //     sessionStorage.setItem("marketId_w", null);
          //   }
          // }
          if (value) {
            if (value.length != 0) {
              sessionStorage.setItem("marketId_w", value[0].marketId);
              if (value[0].marketId == 18) {
                this.$store.commit("setIsFlagss", 1);
                localStorage.setItem("bidFlagss", 1);
              } else if (value[0].marketId == 28) {
                this.$store.commit("setIsFlagss", 2);
                localStorage.setItem("bidFlagss", 2);
              }
            } else {
              this.$store.commit("setIsFlagss", 0);
              sessionStorage.setItem("marketId_w", null);
            }
          }
          this.$store.commit("setFirmList", value);
          for (let i = 0; i < value.length; i++) {
            if (value[i].marketId == window.a.application[0].marketId) {
              localStorage.setItem("atPresentFirmId", value[i].firmId);
              this.queryCommodityStrategy();
              // this.$router.push('/iframeController');
              return;
            }
          }
        }
      });
    },
    // 商品策略查询
    queryCommodityStrategy() {
      const _this = this;
      http
        .postFrontGX(protocol.param_queryCommodityStrategy)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.$store.commit("setCommodityStrategy", value);
          } else {
            this.$EL_MESSAGE(message);
            setTimeout(() => {
              _this.queryCommodityStrategy();
            }, 1000);
          }
        })
        .catch(e => {
          setTimeout(() => {
            _this.queryCommodityStrategy();
          }, 1000);
        });
    },
    param_selectFirmType(item) {
      protocolFwd.param_selectFirmType.param.firmId = item;
      http.postFront(protocolFwd.param_selectFirmType).then(response => {
        // console.log(response, 'value')
        const { code, message, value } = response.data;
        if (code == 0) {
          if (value) {
            for (let key in protocolFwd) {
              // console.log(protocolFwd[key], 'protocolFwd[key]')
              if (protocolFwd[key].param) {
                if ("firmType" in protocolFwd[key].param) {
                  if (value != null || value.firmType != null) {
                    protocolFwd[key].param.firmType = value.firmType;
                  }
                }
              }
            }
            if (value != null || value.firmType != null) {
              this.$store.commit("setUserType", value.firmType);
              window.sessionStorage["userType"] = value.firmType;
            }
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    onSuccess() {
      // console.log('滑动成功')
      this.slideText = "成功";
    },
    onFail() {
      // console.log('滑动失败')
      this.slideText = "向右滑";
    },
    onRefresh() {
      // console.log('点击了刷新')
      this.slideText = "向右滑";
    },
    getUrl() {
      let urlArr = window.location.href.split("%3FieoId%3D");
      this.ieoId = urlArr[1];
    },
    getIdentificationStatus(firmIds) {
      protocolFwd.param_enterpriseCertification.param.firmId = firmIds;
      http
        .postFront(protocolFwd.param_enterpriseCertification)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            if (Object.keys(value).length !== 0) {
              sessionStorage.setItem("userFirmId", value.firm_id);
            } else {
              sessionStorage.setItem("userFirmId", firmIds);
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    // submitForm(formName) {
    //   this.$refs[formName].validate(valid => {
    //     if (valid) {
    //       const self = this
    //       let tel = self.ruleForm.tel
    //       // 向后台发送用户登录名和密码
    //       protocol.param_login.param.moblie = self.ruleForm.tel
    //       protocol.param_login.param.password = md5(self.ruleForm.tel+self.ruleForm.passWord);
    //       protocol.param_login.param.code = self.ruleForm.tupian
    //       protocol.param_login.param.captchaId =
    //         protocol.param_validImgCaptcha.param.capid
    //       this.btnEnable=true;
    //       http.post(protocol.param_login).then(response => {
    //         //console.log(response.data)
    //         let { code, msg, values } = response.data
    //         if (code == 0) {
    //           //console.log(response.data)
    //           // 当记住密码的checbox选中时，像localStorage里存入一下用户输入的用户名和密码
    //           if (self.checked == true) {
    //             self.setlocalStorage(self.ruleForm.tel, self.ruleForm.passWord)
    //           } else {
    //             self.setlocalStorage('', '')
    //           }
    //           let sessionInfo = {}
    //           sessionInfo.isLogin = true
    //           sessionInfo.userId = values.user.userId
    //           sessionInfo.sessionStr = values.sessionStr
    //           sessionInfo.firmCode = values.user.firmCode
    //           sessionInfo.tel = tel
    //           sessionInfo.firmId = values.user.firmId
    //           this.$store.commit('setSessionInfo', sessionInfo)
    //           let sessionStr = response.data.values.sessionStr
    //           let sessionUserId = response.data.values.user.userId
    //           let sessionFirmId = response.data.values.user.firmId
    //           // 将session信息存到localstorage中
    //           sessionStorage.sessionStr = sessionStr
    //           sessionStorage.sessionUserId = sessionUserId
    //           // 修改请求参数的登录信息
    //           for (let item in protocol) {
    //             if ('sessionStr' in protocol[item].param) {
    //               protocol[item].param.sessionStr = sessionStr
    //               protocol[item].param.userId = sessionUserId
    //             }
    //           }
    //           for (let key in protocol) {
    //             if ('firmId' in protocol[key].param) {
    //               protocol[key].param.firmId = sessionFirmId
    //             }
    //             // if ('ffirmId' in protocol[key].param) {
    //             //   protocol[key].param.ffirmId = sessionFirmId
    //             // }
    //           }
    //           http.post(protocol.param_getNickNameStatus).then(response => {
    //             let { code, msg, values } = response.data.value;
    //             this.$store.commit('setNickNameStatus', code);
    //           })
    //           http.post(protocol.param_getHighCerStatus).then(response => {
    //             let { code, msg, value } = response.data;
    //               if(value.item.length!=0){
    //                 let highCerStatus=value.item[0].status;
    //                 if(highCerStatus==='0'){
    //                   this.$store.commit('setHighCerStatus', highCerStatus);
    //                 }
    //               }
    //           })
    //             this.btnEnable=false;
    //           // 登录成功之后跳转到首页
    //           this.$router.push('/')
    //         } else {
    //           this.VerificationCode()
    //           this.$EL_MESSAGE(msg)
    //           this.btnEnable=false;
    //         }
    //       })
    //     } else {
    //       //console.log('error submit!!')
    //       return false
    //     }
    //   })
    // },
    // 查询用户报名的竞价商品
    param_queryAuctionEnrolment(firmIds) {
      protocolFwd.param_queryAuctionEnrolment.param = {
        isFinish: null,
        orderStatus: null,
        payStatus: null,
        typeList: null,
        page: 0,
        size: 1000,
        sort: null,
        firmId: firmIds
      };
      http.postFront(protocolFwd.param_queryAuctionEnrolment).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          // console.log(value.content)
          this.$store.commit("setSelectFirmApplyCommodity", value.content);
        } else {
          // console.log(message)
        }
      });
    },
    // 查询考试是否通过
    getIsPass() {
      http.postFront(protocol.param_questinbankfrontquery).then(response => {
        var { code, value, message } = response.data;
        if (Number(code) === 0) {
          console.log("考试查询", value);
          if (value && value.length > 0) {
            console.log("考试通过");
            if (value[0].answerIsTrue) {
              // 已通过
              localStorage.setItem("examination_w", true);
            } else {
              // 未通过
              localStorage.setItem("examination_w", false);
            }
          } else {
            console.log("考试未通过");

            // 未通过
            localStorage.setItem("examination_w", false);
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // if(this.slideText!='成功'){
          //   return this.$EL_MESSAGE('请重新移动滑块')
          // }
          // if (this.code1 != this.ruleForm.tupian) {
          //   this.$EL_MESSAGE('验证码输入不正确')
          //   return
          // }
          const self = this;
          let tel = self.ruleForm.tel;
          // 向后台发送用户登录名和密码
          protocol.param_authn_authn_login.param.loginAccount = self.ruleForm.tel.replace(
            /\s*/g,
            ""
          );
          protocol.param_authn_authn_login.param.captchaCode =
            self.ruleForm.tupian;
          protocol.param_authn_authn_login.param.captchaId =
            protocol.param_validImgCaptcha.param.capid;
          // protocol.param_authn_authn_login.param.clientIp = this.clientIp
          protocol.param_authn_authn_login.param.terminalType = 4;
          protocol.param_authn_authn_login.param.type = 1;
          //protocolUser.param_authn_authn_login.param.code = this.type === '2' ? this.smsCode : ''
          protocol.param_authn_authn_login.param.password = md5(
            "47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=" +
              self.ruleForm.passWord
          );
          // self.ruleForm.passWord;
          // protocol.param_login.param.moblie = self.ruleForm.tel
          // protocol.param_login.param.password = md5(self.ruleForm.tel + self.ruleForm.passWord);
          // protocol.param_login.param.code = self.ruleForm.tupian
          // protocol.param_login.param.captchaId =
          //   protocol.param_validImgCaptcha.param.capid
          //  protocol.param_login.param.capid =
          // protocol.param_validImgCaptcha.param.capid
          this.btnEnable = true;
          http.postFront(protocol.param_authn_authn_login).then(response => {
            //console.log(response.data)
            let { code, message, value } = response.data;
            if (code == 0) {
              //console.log(response.data)
              // 当记住密码的checbox选中时，像localStorage里存入一下用户输入的用户名和密码
              if (self.checked == true) {
                self.setlocalStorage(self.ruleForm.tel, self.ruleForm.passWord);
              } else {
                self.setlocalStorage("", "");
              }

              let sessionInfo = {};
              sessionInfo.isLogin = true;
              sessionInfo.userId = value.userId;
              sessionInfo.sessionStr = value.sessionStr;
              //sessionInfo.firmCode = value.user.firmCode;
              sessionInfo.tel = self.ruleForm.tel;
              sessionInfo.firmId = value.firmId;
              sessionInfo.userCode = value.userCode;
              //sessionInfo.userType = value.user.userType;
              this.$store.commit("setSessionInfo", sessionInfo);
              let sessionStr = response.data.value.sessionStr;
              let sessionUserId = response.data.value.userId;
              let sessionFirmId = response.data.value.firmId;
              //let sessionFirmCode = response.data.value.user.firmCode;
              let sessionTel = self.ruleForm.tel;
              //let sessionUserType = response.data.value.user.userType;
              // 将session信息存到localstorage中
              sessionStorage.sessionStr = sessionStr;
              sessionStorage.sessionUserId = sessionUserId;
              //sessionStorage.sessionFirmCode = sessionFirmCode;
              sessionStorage.sessionTel = sessionTel;
              sessionStorage.userCode = response.data.value.userCode;
              sessionStorage.userName = response.data.value.userName;
              //sessionStorage.userType = sessionUserType;

              // 修改请求参数的登录信息
              for (let item in protocol) {
                if (protocol[item].param) {
                  if ("sessionStr" in protocol[item].param) {
                    protocol[item].param.sessionStr = sessionStr;
                    protocol[item].param.userId = sessionUserId;
                  }
                }
              }
              for (let key in protocol) {
                if (protocol[key].param) {
                  if ("userId" in protocol[key].param) {
                    protocol[key].param.userId = sessionUserId;
                  }
                  if ("firmId" in protocol[key].param) {
                    protocol[key].param.firmId = sessionFirmId;
                  }
                  if ("ffirmId" in protocol[key].param) {
                    protocol[key].param.ffirmId = sessionFirmId;
                  }
                  if ("firmCode" in protocol[key].param) {
                    protocol[key].param.firmCode = sessionFirmCode;
                  }
                  if ("tel" in protocol[key].param) {
                    protocol[key].param.tel = sessionTel;
                  }
                }
                if (protocol[key].param) {
                  if ("userId" in protocol[key].param) {
                    protocol[key].param.userId = sessionUserId;
                  }
                  if ("firmId" in protocol[key].param) {
                    protocol[key].param.firmId = sessionFirmId;
                  }
                }
              }

              for (let key in protocolFwd) {
                if (protocolFwd[key].param) {
                  if ("firmId" in protocolFwd[key].param) {
                    protocolFwd[key].param.firmId = sessionFirmId;
                  }
                  if ("userId" in protocolFwd[key].param) {
                    protocolFwd[key].param.userId = sessionUserId;
                  }
                }
              }
              // this.param_selectFirmType(value.firmId);
              // this.param_getMarketParameter(value.firmId)
              // this.param_queryAuctionEnrolment(value.firmId)
              // this.getIdentificationStatus(value.firmId)
              //   for (let key in protocolFwd) {
              //   if (protocolFwd[key].param) {
              //     if ("firmId" in protocolFwd[key].param) {
              //       protocolFwd[key].param.firmId = sessionFirmId;
              //     }
              //     if ("ffirmId" in protocolFwd[key].param) {
              //       protocolFwd[key].param.ffirmId = sessionFirmId;
              //     }
              //     if ("firmCode" in protocolFwd[key].param) {
              //       protocolFwd[key].param.firmCode = sessionFirmCode;
              //     }
              //     if ("tel" in protocolFwd[key].param) {
              //       protocolFwd[key].param.tel = sessionTel;
              //     }
              //   }
              //   if (protocolFwd[key].data) {
              //     if ("firmIds" in protocolFwd[key].data) {
              //       protocolFwd[key].data.firmIds = sessionFirmId;
              //     }
              //     if ("firmId" in protocolFwd[key].data) {
              //       protocolFwd[key].data.firmId = sessionFirmId;
              //     }
              //   }
              // }
              // http.postFront(protocol.param_getNickNameStatus).then(response => {
              //   let {
              //     code,
              //     msg,
              //     values
              //   } = response.data.value;
              //   this.$store.commit('setNickNameStatus', code);
              // })
              // http.postFront(protocol.param_getHighCerStatus).then(response => {
              //   let {
              //     code,
              //     msg,
              //     value
              //   } = response.data;
              //   if (value.item.length != 0) {
              //     let highCerStatus = value.item[0].status;
              //     if (highCerStatus === '0') {
              //       this.$store.commit('setHighCerStatus', highCerStatus);
              //     }
              //   }
              // })
              // this.$router.push('/iframeController');
              // const userId = this.$store.state.common.sessionInfo.userId
              const time = new Date();
              const timer = util.formatnewDay(time);
              const obj = {};
              obj.userId = sessionUserId;
              obj.time = timer;

              this.$router.push("/HomePage1");

              // this.searchFirmId(response.data.value.userId);
              this.btnEnable = false;
              this.getIsPass();
              // 登录成功之后跳转到首页
              // this.$store.commit('setHeaderText', window.a.application[0])
              localStorage.setItem(
                "setHeaderText",
                JSON.stringify(window.a.application[0])
              );
            } else if (code === "base_register_028") {
              // 密码错误,发送请求获取今日密码输入次数
              this.queryPwdCount();
              this.btnEnable = false;
              this.VerificationCode();
              this.$EL_MESSAGE(message);
            } else {
              this.VerificationCode();
              this.$EL_MESSAGE(message);
              this.btnEnable = false;
            }
          });
        } else {
          //console.log('error submit!!')
          return false;
        }
      });
    },
    // 获取当日密码输错次数
    queryPwdCount() {
      protocol.param_queryPwdCount.param.loginAccount = this.ruleForm.tel.replace(
        /\s*/g,
        ""
      );
      http.postFront(protocol.param_queryPwdCount).then(response => {
        var { code, message, value } = response.data;
        if (Number(code) === 0) {
          if (value != -1) {
            this.$EL_MESSAGE(
              `账号或登录密码不正确,您还有${value}次机会尝试登录`
            );
          } else {
            this.$EL_MESSAGE(`账号或登录密码不正确`);
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    //像localStorage里存入从页面获取的用户名和密码；
    setlocalStorage(c_tel, c_pwd) {
      localStorage.sitetel = c_tel;
    },
    getlocalStorage: function() {
      this.ruleForm.tel = localStorage.getItem("sitetel"); //保存到保存数据的地方
    },
    // 点击获取验证码
    VerificationCode: function() {
      http.getRes(protocol.param_getCaptcha).then(response => {
        if (response.data) {
          this.code1 = response.data.value.code;
          console.log(response);
          this.captchaData =
            "data:image/png;base64," + response.data.value.data;
          // 图片校验时的参数
          protocol.param_validImgCaptcha.param.capid = response.data.value.id;
        } else {
          this.$EL_MESSAGE(response.data);
        }
      });
    },
    //根据语言改变样式
    setLanguageCss() {
      //防止清缓存后数据消失
      this.$i18n.locale =
        localStorage.lang || navigator.language.slice(-2).toLowerCase();
      switch (localStorage.lang) {
        case "en":
          localStorage.setItem("isEnPhoto", true);
          this.isEnPhoto = localStorage.isEnPhoto;
          break;
        default:
          break;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.forget {
  float: left;
  margin-top: 15px;
  font-size: 15px;
  font-weight: 400;
font-size: 16px;
color: #333333;
  // margin-left: 172px;
}
.photo {
  width: 100%;
  // min-width: 1800px;
  height: 100vh;
  /* margin-top: -60px; */
  /* background: url("../../../assets/configImages/login_bg.png") center center no-repeat; */
  background: url("../../../assets/configImages/new_login_img.jpg") center
    center no-repeat;
  background-size: 100% 100vh;
  /* background-size: cover; */
}
.all{
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
  box-sizing: border-box;
}
.logo{
  width: 40%;
  display: flex;
  align-items: center;

}
.lable{
  font-family: Source Han Sans SC;
  font-weight: bold;
  font-size: 20px;
  color: #1C1C1C;
  // line-height: 66px;
  span{
    font-weight: 400;
  }
  span::before{
    position: absolute;
    display: inline-block;
    content: '*';
    color: red;
    /* margin-right: 10px; */
    top: -4px;
    left: 38px;
    font-size: 23px;
  }
}
.lable1{
  span::before{
    left: 78px;
  }
}
.lable2{
  span::before{
    left: 98px;
  }
}
.content{
  width: 100%;
  padding: 0 50px;
  padding-top: 25px;
  box-sizing: border-box;
}
.all .el-form-item__content{
  display: flex;
  flex-direction: column;
  padding-top: 34px;
  line-height: normal;
}
.all .el-form-item{
  margin-bottom: 30px;
}
.all /deep/ .el-input__inner {
  border-radius: 0;
    border: none;
    padding: 0;
    border-bottom: 1px solid #DCDFE6;
}
.all .el-form-item__label{
  text-align: left;
}
.box {
  border-radius: 10px;
  /* margin: 10% 0 5% 55%; */
  width: 40%;
  /* height: 450px; */
  /* position: absolute;
  top: 18%;
  right: 4%; */
  border-radius: 20px;
  background-color: #ffffff;
  padding-bottom: 87PX;
  /* box-shadow: 0px 0px 2px #666666; */
}
.immediately {
  width: 100%;
  /* font-size: 22px;
  text-align: center;
  color: #666666; */
}
.tupian {
  position: absolute;
  margin: auto;
  right: 0px;
  bottom: 6px;
  width: 97px;
  height: 34px;
}
.remember {
  height: 73px;
  position: relative;
}
.remember .changes {
  position: absolute;
  right: 28%;
  top: 30px;
}
.remember .word {
  position: absolute;
  top: 27px;
  left: 425px;
}
.btn {
  cursor: pointer;
  border: 0;
  outline-style: none;
  width: 100%;
  height: 45px;
  color: #ffffff;
  background-color: #0F348F;
  border-radius: 6px;
  // margin-left: 168px;
  font-size: 16px;
}
.reg {
  float: right;
  margin-top: 15px;
  font-size: 15px;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  // margin-right: 100px;
}
.yanZheng {
  margin-bottom: 0px;
}
</style>
<style>
.One .el-form-item__content {
  /* width: 500px; */
}
.One .eleInputLogin {
  width: 100%;
}
.One .el-form-item__label {
  font-size: 16px;
  width: 150px;
  padding-left: 0;
  margin-right: 18px;
  text-align: right;
  display: inline-block;
}
.One .el-input__inner {
  opacity: 0.784;
}
.One .el-form-item__error {
  /* left: 167px; */
}
.Two .el-form-item__content {
  /* width: 500px; */
}
.Two .eleInputLogin {
  width: 100%;
}
.Two .el-form-item__label {
  font-size: 16px;
  width: 150px;
  padding-left: 0;
  margin-right: 18px;
  text-align: right;
  display: inline-block;
}
.Two .el-form-item__error {
  /* left: 167px; */
}
.Two .el-input__inner {
  opacity: 0.784;
}
.Three .el-form-item__content {
  /* width: 500px; */
}
.Three .eleInputLogin {
  width: 100%;
}
.Three .el-form-item__label {
  font-size: 16px;
  width: 150px;
  padding-left: 0;
  margin-right: 18px;
  text-align: right;
  display: inline-block;
}
.Three .el-input__inner {
  opacity: 0.784;
}
.Three .el-form-item__error {
  /* left: 167px; */
}
.enphoto .el-form-item__label {
  font-size: 14px;
}
div.enphoto .remember .enChanges {
  right: 48%;
}
div.enphoto .remember .enWord {
  left: 310px;
}
div.enphoto input::-webkit-input-placeholder {
  font-size: 12px;
}
div.enphoto input:-moz-placeholder {
  font-size: 12px;
}
div.enphoto input::-moz-placeholder {
  font-size: 12px;
}
div.enphoto input:-ms-input-placeholder {
  font-size: 12px;
}
div.enphoto .eleInputLogin {
  width: 325px !important;
}
div.enphoto .tupian {
  position: absolute;
  margin: auto;
  right: 9px;
  bottom: 3px;
  width: 97px;
  height: 34px;
}
.loginSubmitBtn {
  margin-top: 25px;
}
.footerBox {
  margin-top: 80vh;
  height: 15vh;
  width: 100%;
  background-color: #4394e5;
}
</style>
