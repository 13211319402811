<template>
  <el-menu
    :default-active="activeIndexs"
    class="el-menu-vertical-demo"
    background-color="#0193de00"
    text-color="#8EB8BC"
    mode="horizontal"
    active-text-color="#ffffff"
  >
    <div style="display: flex; height: 60px">
      <img
        src="../../../assets/images/aside/logo.png"
        alt=""
        style="height:60px;padding-top:10px;"
      />
      <!-- <img :src="logoUrl" alt="" style="height: 60px" /> -->
    </div>
    <div
      style="position: absolute; top: 0; right: 0; display: flex; height: 60px"
    >
      <div style="margin-left:20px;" v-if="isPcClient">
        <Titlebtn type="min" />
        <Titlebtn type="max" />
        <Titlebtn type="close" />
      </div>
    </div>
  </el-menu>
</template>

<script>
import Titlebtn from "./Titlebtn.vue";

export default {
  components: {
    Titlebtn
  },
  data() {
    return {
      isPcClient: false,
      marketList: [],
      isDisabled1: false,
      isDisabled: false,
      gxFlagss: true,
      bidFlagss: 0,
      bidFlag: false,
      dialogVisible2: false,
      inviteInput: "",
      lstName: "挂牌",
      isAssets: true,
      dialogVisible1: false,
      dialogVisible: false,
      logoUrl: "",
      headerDate: "",
      isUserShow: false,
      timeInterval2: null,
      timeInterval1: null,
      userMes: "",
      atPrenterIndex: "",
      activeIndexs: "",
      asideData: [],
      // isPcClient: window.g.IS_PC_CLIENT,
      userCenter: [
        {
          name: "账户&安全",
          path: "/userOrSafety",
          icon: require("../../../assets/images/aside/user.png"),
          iconSelect: ""
        },
        {
          name: "身份认证",
          path: "/personalEnterpriseAuth",
          icon: require("../../../assets/images/aside/user.png"),
          iconSelect: ""
        },
        {
          name: "员工管理",
          path: "/personalCenter/staffAccountMgt",
          icon: require("../../../assets/images/aside/user.png"),
          iconSelect: ""
        },
        {
          name: "退出登录",
          path: "/userCenter1",
          icon: require("../../../assets/images/aside/user.png"),
          iconSelect: ""
        }
        // {
        //   name: '修改密码',
        //   path: '/accountSafe',
        //   icon: require('../../../assets/images/aside/mima.png'),
        //   iconSelect: ''
        // },
        // {
        //   name: '我的地址',
        //   path: '/address',
        //   icon: require('../../../assets/images/aside/dizhi.png'),
        //   iconSelect: ''
        // }
      ],
      posotion: [
        {
          name: "我的持仓",
          path: "/myPosition",
          icon: "home"
        },
        {
          name: "自提物流",
          path: "/logisticsBill",
          icon: "flag"
        },
        {
          name: "现货入库操作",
          path: "/warehousingApply",
          icon: "claim"
        }
      ],
      AssetCenter: [
        {
          name: "资产状况",
          path: "/conditionOfAssets"
        }
      ],
      BaseMarkets: [
        { accName: "预售交易账户", account: "预售交易账户", marketId: "28" }
        // { accName: "挂牌竞价账户", account: "挂牌竞价账户", marketId: "18" },
      ],
      lstTags: [
        { routeName: "现货销售", route: "/ResourceDocuments", flags: "0" },
        { routeName: "现货采购", route: "/purchaseDocument", flags: "1" },
        { routeName: "产品预售", route: "/productionSell", flags: "2" }
      ]
    };
  },
  mounted() {
    if (localStorage.getItem("equipmentFlag_PC")) {
      if (Number(localStorage.getItem("equipmentFlag_PC")) === 1) {
        // pc 客户端
        this.isPcClient = true;
      } else {
        // pc web端
        this.isPcClient = false;
      }
    }
    // this.getLogo();
  },
  methods: {
    // 获取logo
    getLogo() {
      protocol.param_getIndexLogo.param.columnId = window.c.logoPng;
      http.getRes(protocol.param_getIndexLogo).then(response => {
        var { message, code, value } = response.data;
        if (Number(code) === 0) {
          this.logoUrl = value.content[0].titleImg;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.el-dropdown-menu {
  min-width: 109px !important;
}
/deep/ #dialog1 .el-dialog {
  height: 80%;
  overflow: hidden;
  overflow-y: auto;
}
/deep/ .el-dialog::-webkit-scrollbar {
  width: 4px;
}
/deep/ .el-dialog::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
/deep/ .el-dialog::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
/deep/ :focus {
  outline: 0;
}
// /deep/ .el-button {
//   background-color: #b8ddf0;
//   border-color: #0193de;
// }
.el-menu {
  // height: 100vh;
  border: none;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  // width: 136px;
  // min-height: 400px;
  -webkit-app-region: drag;
}
.fontSize {
  font-size: 16px !important;
}
.btn {
  position: fixed;
  bottom: 9px;
  left: 9px;
}
.baseAccBtn {
  color: rgb(142, 184, 188);
  border-bottom-color: transparent;
  background-color: rgb(23, 35, 59);
  border: none !important;
  // height: 100%;
}
.baseAccBtn:hover {
  background-color: #121c2f;
}
.el-button {
  background-color: none !important;
}
/deep/ .el-submenu__title {
  height: 100%;
  font-size: 16px;
}
/deep/ .el-menu.el-menu--popup.el-menu--popup-bottom-start {
  background-color: #fff !important;
}
.d2_title {
  margin: 10px 0;
}
.d2_btns {
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
}
.btns {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
</style>
